import { useQuery } from '@tanstack/vue-query';
import { formsService } from '~/utils/api/formsService';

export function useForms({ per_page = 10, active = true }) {
  const search = ref('');
  const page = ref(0);
  const first = ref(0);

  watch(search, () => {
    page.value = 0;
  });

  const { data, isLoading } = useQuery({
    queryKey: ['forms', page, search, active],
    queryFn: async () => {
      const forms = await formsService.getForms({
        active: active,
        search: search.value,
        page: page.value,
        per_page,
      });

      return forms;
    },
  });

  return { data, search, page, isLoading, per_page, first };
}
