import { fetcher } from '../fetcher';
import type { FormForm } from '../types';
import {
  formFormSchema,
  formsSchema, formTemplateSchema,
  formTemplatesMetaSchema,
  formTemplatesSchema,
} from '~/utils/schemas';

const FORM_TEMPLATES_ENDPOINT = '/form-templates';

export const formTemplatesService = {
  storeFormTemplate: async (payload: FormForm) => {
    return await fetcher(`${FORM_TEMPLATES_ENDPOINT}`, {
      method: 'POST',
      body: payload,
      schema: formTemplateSchema,
    });
  },
  // getFormTemplate: async (form_template_id: number) => {
  //   return await fetcher(`${FORM_TEMPLATES_ENDPOINT}/${form_template_id}`, {
  //     method: 'GET',
  //     schema: formSchema,
  //   });
  // },
  getFormTemplates: async ({
    search,
    page,
    per_page = 10,
  }: {
    search: string;
    page: number;
    per_page?: number;
  }) => {
    const endpoint = `${FORM_TEMPLATES_ENDPOINT}?search=${search}&page=${page + 1}&per_page=${per_page}`;

    return await fetcher(endpoint, {
      method: 'GET',
      schema: formTemplatesMetaSchema,
      meta: true,
    });
  },
  getAllFormTemplates: async () => {
    return await fetcher(`${FORM_TEMPLATES_ENDPOINT}`, {
      method: 'GET',
      schema: formTemplatesSchema,
    });
  },
  deleteTemplate: async (form_template_id: number) => {
    return await fetcher(`${FORM_TEMPLATES_ENDPOINT}/${form_template_id}`, {
      method: 'DELETE',
    });
  },
  editTemplate: async (payload: FormForm, form_template_id: number) => {
    return await fetcher(`${FORM_TEMPLATES_ENDPOINT}/${form_template_id}`, {
      method: 'PATCH',
      body: payload,
      schema: formFormSchema,
    });
  },
};
