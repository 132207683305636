import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { useConfirm } from 'primevue/useconfirm';
import { formTemplatesService } from '~/utils/api/formTemplatesService';

export function useDeleteFormTemplate() {
  const toast = useToast();
  const queryClient = useQueryClient();

  const confirm = useConfirm();

  function confirmDelete(form_id: number) {
    confirm.require({
      message: 'Czy na pewno chcesz usunąć formularz?',
      header: ' ',
      rejectLabel: 'Anuluj',
      rejectProps: {
        label: 'Anuluj',
        severity: 'secondary',
        outlined: true,
        size: 'small',
        rounded: true,
      },
      acceptProps: {
        label: 'Usuń',
        severity: 'danger',
        size: 'small',
        rounded: true,
      },
      accept: () => {
        deleteFormTemplate(form_id);
      },
    });
  }

  const { mutate } = useMutation({
    mutationFn: (form_id: number) =>
      formTemplatesService.deleteTemplate(form_id),
    onError: () => {
      toast.add({
        severity: 'error',
        detail: 'Wystąpił błąd podczas usuwania szablonu',
        life: 3000,
      });

      queryClient.invalidateQueries({ queryKey: ['form-templates'] });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['form-templates'] });
    },
  });

  function deleteFormTemplate(form_id: number) {
    mutate(form_id);
  }

  return {
    confirmDelete,
  };
}
