<script setup lang="ts">
import FormForm from '~/components/FormForm.vue';
import DropdownButton from '~/components/DropdownButton.vue';

const visible = ref(false);
const formTemplateModalVisible = ref(false);

const {
  form: formData,
  errors,
  handleForm,
  resetForm,
  validate,
} = useAddForm(() => (visible.value = false));

const addTemplateToForm = (template) => {
  Object.keys(template).forEach((key) => {
    formData[key] = template[key];
  });
  if (formData.start_date) {
    formData.start_date = new Date(formData.start_date);
  }
  if (formData.end_date) {
    formData.end_date = new Date(formData.end_date);
  }
  visible.value = true;
};
</script>

<template>
  <div class="relative">
    <DropdownButton>
      <i class="pi pi-plus"></i>
      Dodaj formularz
      <template #dropdown>
        <div class="flex flex-col gap-3">
          <div class="cursor-pointer" @click="visible = true">
            Nowy formularz
          </div>
          <div class="cursor-pointer" @click="formTemplateModalVisible = true">
            Użyj szablonu
          </div>
        </div>
      </template>
    </DropdownButton>
    <LazyFormForm
      v-if="visible"
      v-model:form="formData"
      v-model:visible="visible"
      :errors="errors"
      :validate="validate"
      @save="handleForm"
      @update:visible="(v) => (v === false ? resetForm() : null)"
    />
    <LazyFormTemplateModal
      v-if="formTemplateModalVisible"
      v-model:visible="formTemplateModalVisible"
      @template-selected="addTemplateToForm"
    />
  </div>
</template>
