import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { formsService } from '@/utils/api/formsService';
import type { FormForm } from '@/utils/types';
import type { ResponseError } from '@/utils/responseError';
import { useFormForm } from '~/composables/useFormForm';
import { removeNulls } from '~/utils/removeNulls';

export function useAddForm(onSuccess?: () => void) {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { form, errors, resetForm, handleSubmit, validate } = useFormForm();

  const { mutate, isPending } = useMutation({
    mutationFn: (payload: FormForm) => {
      let payloadCopy = Object.assign({}, payload);
      if (payloadCopy.start_time) {
        payloadCopy.start_time = getHoursMinutesSeconds(payloadCopy.start_time);
      }
      Object.keys(payloadCopy).forEach((key) => {
        if (payloadCopy[key] === null) {
          delete payloadCopy[key];
        }
      });
      return formsService.storeForm(payloadCopy);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['forms'] });
    },
    onError: (error: ResponseError) => {
      if (error.status === 422) {
        toast.add({
          severity: 'error',
          summary: error.message,
          life: 3000,
        });

        return;
      }

      toast.add({
        severity: 'error',
        summary: 'Wystąpił błąd podczas dodawania formularza',
        life: 3000,
      });
    },
  });

  const handleForm = handleSubmit((values) => {
    if (Object.keys(errors.value).length > 0) return;

    mutate(form, {
      onSuccess: () => {
        resetForm();

        onSuccess && onSuccess();
      },
    });
  });

  return {
    isPending,
    form,
    handleForm,
    errors,
    validate,
    resetForm,
  };
}
