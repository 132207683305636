import { useQuery } from '@tanstack/vue-query';
import { formsService } from '~/utils/api/formsService';

export function useShowForm(id: number | null) {
  const { data } = useQuery({
    queryKey: ['forms', id],
    queryFn: () =>
      id !== null ? formsService.getForm(id) : Promise.reject('Invalid ID'),
  });

  return { data };
}
