import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { useConfirm } from 'primevue/useconfirm';
import { formsService } from '@/utils/api/formsService';

export function useDeleteForm() {
  const toast = useToast();
  const queryClient = useQueryClient();

  const confirm = useConfirm();

  function confirmDelete(form_id: number) {
    confirm.require({
      message: 'Czy na pewno chcesz usunąć formularz?',
      header: ' ',
      rejectLabel: 'Anuluj',
      rejectProps: {
        label: 'Anuluj',
        severity: 'secondary',
        outlined: true,
        size: 'small',
        rounded: true,
      },
      acceptProps: {
        label: 'Usuń',
        severity: 'danger',
        size: 'small',
        rounded: true,
      },
      accept: () => {
        deleteForm(form_id);
      },
    });
  }

  const { mutate } = useMutation({
    mutationFn: (form_id: number) => formsService.deleteForm(form_id),
    onError: () => {
      toast.add({
        severity: 'error',
        detail: 'Wystąpił błąd podczas usuwania formularza',
        life: 3000,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['forms'] });
    },
  });

  function deleteForm(form_id: number) {
    mutate(form_id);
  }

  return {
    confirmDelete,
  };
}
