import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { formsService } from '@/utils/api/formsService';
import type { FormForm } from '@/utils/types';
import type { ResponseError } from '@/utils/responseError';

type EditFormOptions = {
  form_id?: number | null;
  form_object?: object | null;
};

export function useEditForm(
  { form_id = null, form_object = null }: EditFormOptions = {},
  onSuccess?: () => void,
) {
  const toast = useToast();
  const queryClient = useQueryClient();

  const formData = ref(form_object) ?? useShowForm(form_id).data;

  const { form, errors, resetForm, handleSubmit, setDefaults } = useFormForm();

  watch(
    [formData],
    () => {
      if (!formData.value) return;
      for (const key in formData.value) {
        if (form.hasOwnProperty(key)) {
          form[key] = formData.value[key];
        }
      }
      if (typeof form.start_date === 'string') {
        form.start_date = new Date(form.start_date);
      }
      if (typeof form.start_time === 'string') {
        form.start_time = new Date(form.start_time);
      }
      if (typeof form.end_date === 'string') {
        form.end_date = new Date(form.end_date);
      }
      setDefaults();
    },
    {
      immediate: true,
    },
  );

  const { mutate, isPending } = useMutation({
    mutationFn: (payload: FormForm) => {
      const payloadCopy = JSON.parse(JSON.stringify(payload));
      if (payloadCopy.start_time) {
        payloadCopy.start_time = getHoursMinutesSeconds(payload.start_time);
      }
      return formsService.editForm(
        payloadCopy,
        form_object ? form_object.id : form_id,
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['forms'] });
    },
    onError: (error: ResponseError) => {
      if (error.status === 422) {
        toast.add({
          severity: 'error',
          summary: error.message,
          life: 3000,
        });

        return;
      }

      toast.add({
        severity: 'error',
        summary: 'Wystąpił błąd podczas dodawania formularza',
        life: 3000,
      });
    },
  });

  const handleForm = handleSubmit((values) => {
    if (Object.keys(errors.value).length > 0) return;

    mutate(form, {
      onSuccess: () => {
        onSuccess && onSuccess();
      },
    });
  });

  return {
    isPending,
    form,
    handleForm,
    errors,
    resetForm,
  };
}
