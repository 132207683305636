<script setup lang="ts">
import { ref } from 'vue';

const open = ref(false);

const op = ref();
const toggle = (event) => {
  op.value.toggle(event);
};
</script>

<template>
  <div class="relative">
    <PrimeButton class="font-bold" size="small" rounded @click="toggle">
      <slot />
      <i class="pi pi-angle-down"></i>
    </PrimeButton>
    <PrimePopover
      ref="op"
      class="rounded-md border border-gray-200 bg-white px-3 py-2"
    >
      <slot name="dropdown" />
    </PrimePopover>
  </div>
</template>

<style scoped></style>
