<script setup lang="ts">
definePageMeta({
  layout: 'authorized',
  title: 'Formularze',
  middleware: ['auth'],
});

useHead({
  title: 'Formularze',
});
</script>

<template>
  <PrimeCard>
    <template #content>
      <PrimeTabs lazy value="0" scrollable>
        <PrimeTabList class="w-full">
          <PrimeTab class="flex-1" value="0">Aktywne</PrimeTab>
          <PrimeTab class="flex-1" value="1">Kopie robocze</PrimeTab>
          <PrimeTab class="flex-1" value="2">Szablony</PrimeTab>
        </PrimeTabList>

        <PrimeTabPanels>
          <PrimeTabPanel value="0" class="py-4">
            <FormsTable :active="true" />
          </PrimeTabPanel>

          <PrimeTabPanel value="1" class="py-4">
            <FormsTable :active="false" />
          </PrimeTabPanel>

          <PrimeTabPanel value="2" class="py-4">
            <FormTemplatesTable />
          </PrimeTabPanel>
        </PrimeTabPanels>
      </PrimeTabs>
    </template>
  </PrimeCard>
</template>
