import { useQuery } from '@tanstack/vue-query';
import { formTemplatesService } from '~/utils/api/formTemplatesService';

export function useFormTemplates(per_page = 10) {
  const search = ref('');
  const page = ref(0);

  watch(search, () => {
    page.value = 0;
  });

  const { data, isLoading } = useQuery({
    queryKey: ['form-templates', page, search],
    queryFn: async () => {
      const formTemplates = await formTemplatesService.getFormTemplates({
        search: search.value,
        page: page.value,
        per_page,
      });

      return formTemplates;
    },
  });

  return { data, search, page, isLoading, per_page };
}
